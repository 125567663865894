import { useEffect } from 'preact/hooks';

import { initClientI18n } from './client-config';
import {
	$isInitialized,
	setI18nConfiguration,
	type I18nConfiguration,
} from './store';

export function I18nInitializer({
	initialConfiguration,
}: {
	initialConfiguration: I18nConfiguration;
}) {
	setI18nConfiguration(initialConfiguration);
	useEffect(() => {
		initClientI18n(initialConfiguration).then(() =>
			$isInitialized.set(true)
		);
	}, []);
	return null;
}
